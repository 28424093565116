<template>
  <div class="tutorial">
    <div class="header w-full mb-4">
      <div class="w-full flex flex-col items-center md:flex-row">
        <div class="w-full md:w-3/12">
          <img src="@/assets/logo.png" alt="" />
        </div>
        <div class="w-full md:w-6/12 my-4 md:m-0">
          <h1 class="text-primary text-4xl md:text-5xl text-center">
            {{ this[this.kind].title }}
          </h1>
        </div>
        <div class="w-full md:w-3/12"></div>
      </div>
      <p class="text-base text-primary text-center">
        {{ this[this.kind].description }}
      </p>
    </div>
    <div class="content flex items-center justify-between flex-col md:flex-row">
      <div
        class="tutorial__video flex justify-center mb-4 md:mb-0 md:w-6/12 md:mr-4"
      >
        <video controls class="max-h-screen">
          <source
            :src="require(`@/assets/tutorial/${this.kind}.mp4`)"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="tutorial__content md:w-6/12">
        <div v-if="this.kind === 'windows'">
          <p>
            Sollten Sie bereits eine Captrain-Signatur in Ihrer Outlook Desktop
            App hinterlegt haben, können Sie direkt mit
            <b>Schritt 9</b> beginnen. Andernfalls starten Sie bitte mit
            <b>Schritt 1</b>.
          </p>
          <br />
        </div>
        <ul>
          <li v-for="(step, index) in this[this.kind].steps" :key="index">
            {{ index + 1 }}.
            <span v-html="step"></span>
          </li>
        </ul>
        <button
          @click="copyToClip()"
          class="bg-white border-2 border-primary px-8 py-3 text-gray mt-4 text-center w-full"
        >
          <div class="text-gray">Quellcode von Ihrer Signatur kopieren</div>
        </button>
      </div>
    </div>
    <div class="my-4 hidden" id="signatureHtml" v-html="signature"></div>
  </div>
</template>

<script>
export default {
  name: "Captrain-Tutorial",
  data() {
    return {
      macos: {
        title: "Signature-Anleitung",
        description: "macOS Apple Mail",
        steps: [
          "Öffnen Sie Ihr Apple Mail.",
          "Klicken Sie oben rechts auf <b>Mail</b> im Kontextmenu wählen Sie <b>Einstellungen</b>…",
          "Wählen Sie nun den Reiter <b>Signaturen</b> aus.",
          "Wählen Sie Ihr Postfach aus.",
          "Klicken Sie in der mittleren Spalte auf das +",
          "Bennen Sie nun Ihre Signatur. Wählen Sie im Anschluss im unteren Teil des Fenster bei <b>Signatur auswählen</b>, ihre Signatur aus.",
          "Schließen Sie nun ihr Apple Mail komplett",
          "Öffnen Sie den Finder und wählen im obigen Reiter <b>Gehe zu</b> aus.",
          "Wählen Sie im Kontextmenu <b>Gehe zum Ordner</b>… aus.",
          "Fügen Sie folgenden Pfad ein <b>~/Library/Mail/V8/MailData/Signatures/</b> bestätigen Sie ihre Eingabe mit <b>öffnen</b>.",
          "Sie sollten nun eine Datei mit der Endung .mailsignature vorfinden. Wählen Sie mit Rechtsklick im Kontextmenu <b>Öffnen mit > TextEdit</b> aus.",
          "Entfernen Sie den Inhalt der mit <body> beginnt und mit </body> endet.",
          "Kopieren Sie nun den Quellcode aus dem WebTool und fügen diesen nun in das Dokument ein.",
          "Speicher Sie das Dokument ab.",
          "Öffnen sie erneut mit der rechten Maustaste das Kontextmenu. Klicken Sie nun auf <b>Informationen</b>.  Setzten Sie in dem Fenster den Haken auf <b>Gesperrt</b>.",
          "Öffnen Sie ihr Apple Mail erneut.",
          "Sie sehen nun Ihre neue Signatur.",
        ],
      },
      office365: {
        title: "Signature-Anleitung",
        description: "Office 365",
        steps: [
          "Gehen Sie auf die Webseite von Office: <a href='https://www.office.com/' target='_blank' style='border-bottom: 1px solid #262626'>https://www.office.com/</a>",
          "Geben Sie Ihre Zugangsdaten ein. Sie werden im Anschluss in Ihr <b>Office 365</b> weitergeleitet.",
          "In der linken Navigation finden Sie das Icon von Outlook.",
          "Nachdem sich <b>Outlook</b> geöffnet hat, klicken Sie oben rechts auf das Zahnrad-Symbol. <br /> Es öffnen sich die Einstellungen.",
          "Klicken Sie im unteren Bereich auf <b>Alle Outlook-Einstellungen anzeigen</b>.",
          "Geben Sie in die Suchleiste <b>E-Mail-Signatur</b> ein.",
          "Kopieren Sie nun Ihre neue Signatur aus dem Captrain-Webtool und fügen Sie die komplette Signatur in das Textfeld unter <b>E-Mail-Signatur</b> ein.<br /> Hinweis: Sollte hier bereits eine Signatur hinterlegt sein, löschen Sie diese im Vorfeld.",
          "Klicken Sie auf <b>Speichern</b>.",
          "Wählen Sie die Signatur anschließend unter <b>Auswählen von Standardsignaturen</b> in den Dropdown-Menüs für <b>Bei neuen Nachrichten</b> und <b>Für Antworten/Weiterleitungen</b> aus.",
          "Ihre neue Signatur steht Ihnen nun in Outlook Web zur Verfügung.",
        ],
      },
      ios: {
        title: "Signature-Anleitung",
        description: "iOS Apple Mail",
        steps: [
          "Öffnen Sie die Einstellungen unter iOS.",
          "Suchen Sie nun nach Mail und öffnen die Einstellungen.",
          "Scrollen Sie weiter nach unten, dort finden Sie <b>Signaturen</b>.",
          "Sie sehen den Namen ihres Postfaches. Löschen Sie eventuell vorhandenen Text, tippen Sie auf den Bildschirm und halten Sie gedrückt, bis das Kontextmenü erscheint. Wählen Sie nun <b>Einfügen</b>.",
          "Die neue E-Mail-Signatur wird nun im Editor angezeigt. Die ursprüngliche Formatierung ist verloren gegangen oder erscheint leicht verändert – <b>das ist normal</b>, Sie müssen lediglich Ihr iPhone schütteln, um das Kontextmenü „<b>Attribute ändern widerrufen</b>“ zu öffnen. Wählen sie in diesem Kontextmenü „Widerrufen“ aus",
          "Ihre neue Signatur ist auf Ihrem IPhone eingebunden.",
        ],
      },
      windows: {
        title: "Signatur Leitfaden",
        description: "Windows Outlook",
        steps: [
          "Öffnen Sie Ihre Outlook App auf dem Desktop",
          "Klicken Sie unter dem Reiter <b>Start</b> auf die Schaltfläche <b>Neue E-Mail</b>. <br />Es öffnet sich ein neues Fenster.",
          "Wählen Sie im obigen Reiter <b>Signatur</b> aus und klicken Sie im Drop-Down-Fenster auf <b>Signaturen…</b>",
          "Klicken Sie im neuen Fenster unter <b>Signatur zum Bearbeiten auswählen</b> auf <b>Neu</b>.",
          "Benennen Sie Ihre neue Signatur und bestätigen Sie Ihre Eingabe mit <b>OK</b>.",
          "Wählen Sie im Dropdown-Menu unter <b>Neue Nachrichten</b> und <b>Antwort/Weiterleitungen</b> die neue Signatur aus.",
          "Bestätigen Sie Ihre Eingaben, indem Sie auf <b>OK</b> klicken.",
          "Schließen Sie nun Outlook. <br/><span>***</span><br/>",
          "Geben Sie in der Suchmaske, welche sich unten Links auf Ihrem Bildschirm befindet, <b>%appdata%</b> ein. <br /> Es öffnet sich nun der Datei-Explorer.",
          "Suchen Sie im Ordner nach <b>Microsoft</b> und öffnen Sie diesen im Anschluss.",
          "Im Ordner <b>Microsoft</b> suchen Sie nach dem Ordner <b>Signatures</b>.",
          "In diesem Ordner sehen Sie nun Ihre erstellte Signatur. <br />Klicken Sie mit Rechtsklick auf die HTML-Datei und wählen Sie im Kontextmenü <b>Öffnen mit > Editor</b> aus.",
          "Entfernen Sie nun den kompletten Inhalt aus dem Dokument.",
          "Kopieren Sie sich aus dem Online-Tool mithilfe des Buttons <b>Signatur-Quellcode kopieren Windows</b> den Inhalt.",
          "Fügen Sie den Inhalt in das leere Textdokument ein und speichern Sie im Anschluss das Dokument ab.",
          "Öffnen Sie nun Ihr Outlook erneut. <br/> Ihre Signatur wurde erfolgreich eingebunden.",
        ],
      },
      kind: "",
      signature: "",
    }
  },
  mounted() {
    console.log(this.$route)
    this.kind = this.$route.query.kind
    this.signature = this.$route.query.signature
  },
  methods: {
    copyToClip() {
      console.log(this.kind != "windows")
      if (this.kind != "windows") {
        let str = document.getElementById("signatureHtml").innerHTML
        function listener(e) {
          e.clipboardData.setData("text/html", str)
          e.clipboardData.setData("text/plain", str)
          e.preventDefault()
        }
        document.addEventListener("copy", listener)
        document.execCommand("copy")
        document.removeEventListener("copy", listener)

        alert("Success Copy to Clipboard")
      } else {
        this.$copyText(this.signature).then(
          function (e) {
            alert("Success Copy Code")
            console.log(e)
          },
          function (e) {
            alert("Can not copy")
          }
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
